import logo from './logo.svg';
import { useState } from 'react';
import FirstScreen from './Screens/FirstScreen';
import SecondScreen from './Screens/SecondScreen';
import { Box, Button, Input } from '@chakra-ui/react';


function App() {

  const [inputValue, setInputValue] = useState("");
  const [originalString, setOriginalString] = useState("");
  const [resultantString, setResultantString] = useState("");

  const handleSubmit = (value) => {
    setInputValue("");
    setOriginalString(value);
    setResultantString(value);
  };

  const handleDelete = (char) => {
    const charIndex = resultantString.indexOf(char);
    const regex = new RegExp(char, "g");
    const newResultantString = resultantString.replace(
      regex,
      (match, index) => {
        if (index === charIndex) {
          return match;
        } else {
          return "";
        }
      }
    );
    setResultantString(newResultantString);
  };

  return (
    <Box as="main" display="flex" alignItems="center" justifyContent="center" bg='blue.50' h='100vh'>
      <Box>
        {originalString === "" ? (
          <FirstScreen onSubmit={handleSubmit} />
        ) : (
          <Box textAlign='center'>
            <SecondScreen
              originalString={originalString}
              resultantString={resultantString}
              onDelete={handleDelete}
              setInputValue={setInputValue}
              setOriginalString={setOriginalString}
              setResultantString={setResultantString}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default App;
