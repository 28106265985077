import { Box, Input, Button, useToast, Heading } from '@chakra-ui/react';
import React, {useState} from 'react'


const FirstScreen = ({onSubmit}) => {
    const [inputValue, setInputValue] = useState("");
    const toast = useToast();

    const handleSubmit = () => {
        if (inputValue.trim() === "") {
            toast({ 
                title: 'Error',
                description: "Please provide a non-empty value.",
                status: "error",
                duration: 3000,
                isClosable: true,
                position: 'top'
            });
        } else {
            onSubmit(inputValue);
        }
    };
  return (
      <Box display='flex' alignItems='center' justifyContent='center'>
          <Box w={{base: '300px', md: '400px', lg: '500px'}} mx='auto' textAlign='center'>
              <Box textAlign='center' mb='5'>
                <Heading fontSize={25}>Duplicate character remover</Heading>
              </Box>
              <Input
                  type="text"
                  value={inputValue}
                  placeholder='Please type in a string.'
                  onChange={(e) => setInputValue(e.target.value)}
                  mb='5'
              />
              <Button onClick={handleSubmit} colorScheme='teal'>Submit</Button>
          </Box>
      </Box>
  )
}

export default FirstScreen