import { CloseIcon } from '@chakra-ui/icons';
import { Button, Box, Text, Heading, VStack } from '@chakra-ui/react';
import React, {useState} from 'react'

const SecondScreen = ({ originalString, resultantString, onDelete, setInputValue, setOriginalString, setResultantString }) => {

  const [colorMap, setColorMap] = useState({});

  const getColor = (char) => {
    if (colorMap[char]) {
      return colorMap[char];
    } else {
      const randomColor = "#" + Math.floor(Math.random() * 16777215).toString(16);
      setColorMap((prevMap) => ({ ...prevMap, [char]: randomColor }));
      return randomColor;
    }
  };

  const duplicateCharacters = [...resultantString].filter(
    (char, i, arr) => arr.indexOf(char) !== i
  );

  const goBack = () => {
      setInputValue("");
      setOriginalString("");
      setResultantString("");
  }

  return (
      <Box>
           <Box>
            {duplicateCharacters.length > 0 ? 
              <>
                <Heading mb='5' fontSize={25}>{duplicateCharacters.length} duplicate character(s) left</Heading>
                <Box display='flex' alignItems='center' gap={2} flexWrap='wrap' justifyContent='center' textAlign='center'>
                  {resultantString.split("").map((char, i) => {
                    return (
                      <Box
                        key={i}
                        style={{
                          backgroundColor: getColor(char)
                        }}

                        bg='gray.300'
                        border='2px solid white'
                        borderRadius={5}
                        p='3'
                        position='relative'
                        w='40px'
                        h='40px'
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                        cursor='pointer'
                        mb='7'
                      >
                        <Text fontSize={23}>{char}</Text>
                        <CloseIcon
                          position='absolute'
                          bg='gray.700' p='1'
                          borderRadius='50%'
                          color='white'
                          top='-5px' right='-5px'
                          fontSize={16}
                          onClick={() => onDelete(char)}
                        />
                      </Box>)
                  })}
                </Box>
              </>
              :
              <Box>
                <Box mb='2'>
                  <Heading>Success!</Heading>
                  <Text fontSize={19}>No duplicate characters found</Text>
                </Box>
                <VStack spacing={2} mb='8'>
                  <Text fontSize={19}>Original String: {originalString}</Text>
                  <Text fontSize={19}>Resultant String: {resultantString}</Text>
                </VStack>
                <Button colorScheme='teal' onClick={goBack}>Back</Button>
              </Box>}
          </Box>
      </Box>
  )
}

export default SecondScreen